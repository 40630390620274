import { fetchAsObservable } from "fetcher!sofe";
import { pluck, concatMap, map } from "rxjs/operators";
import { of, zip } from "rxjs";
import { hasAccess } from "cp-client-auth!sofe";
import { updateSource } from "src/resources/client-sources.resource";
import { putTags } from "./clients.resource";
import { TContact } from "src/common/types";

type CustomField = {
  field_id: string;
  dropdown_values?: string[];
  value?: string | Date;
};

type FilingStatus = {
  key: string;
  value: string;
};

type Tag = {
  id: string;
  name: string;
};

type Address = {
  is_primary: boolean;
  key: string;
  value: {
    address_1: string;
    address_2: string;
    country: string;
    locality: string;
    postal_code: string;
    region: string;
  };
};

type Email = {
  is_primary: boolean;
  key: string;
  value: string;
};

type Phone = {
  is_primary: boolean;
  key: string;
  value: string;
  extension?: string;
};

export type ClientBody = {
  client_since: number;
  name: string;
  use_name_as_external: boolean;
  external_display_name?: string;
  is_business: boolean;
  client_type: string;
  is_active: boolean;
  client_owner_id?: string;
  notes: string;
  filing_status: FilingStatus;
  custom_fields: CustomField[];
  external_id: string;
  tags?: Tag[];
  source?: {
    id: string;
    name: string;
    selected?: {
      id: string;
      name: string;
    };
  };
  rolesUsersTeams?: RolesUsersTeams;

  business_name: string;
  business_type: string;
  industry: string;
  date_established: number;
  tin: string;

  emails: Email[];
  phones: Phone[];
  addresses: Address[];

  contacts: TContact[];
};

type RoleAssignment = {
  role_id: string;
  users: string[];
  teams: string[];
};

type RolesUsersTeams = {
  role_assignments: RoleAssignment[];
  users: string[];
  teams: string[];
};

function addTagsToClient(tags: Tag[], clientId: string) {
  if (clientId == undefined) {
    throw new Error("cannot add Tags to an undefined client");
  }
  return fetchAsObservable(`api/tags`, {
    method: "POST",
    body: {
      tags: tags.map((tag) => {
        return {
          name: tag.name,
          relationship_type: "clients",
          relationship_id: clientId,
        };
      }),
    },
  });
}

export function putRoleAssignments(clientId: string, body: RolesUsersTeams) {
  return fetchAsObservable(`api/clients/${clientId}/assignments`, {
    method: "PUT",
    body,
  });
}

function pruneBody(body: ClientBody) {
  const rolesUsersTeams = body.rolesUsersTeams;
  // We don't want to send this up for api/clients, only for the assignments endpoint
  delete body.rolesUsersTeams;

  const tags = body.tags;
  // We don't want to send this up for api/clients, only for the tags endpoint
  delete body.tags;

  return { rolesUsersTeams, tags };
}

function updateTagsObs(tags: Tag[] | undefined, client: any) {
  if (!!tags && tags.length > 0) {
    return putTags(
      client.id,
      tags.map((tag) => tag.name)
    );
  } else {
    return of(client);
  }
}

function updateSourceObs(body: ClientBody, client: any) {
  if (body.source && body.source.name) {
    return updateSource(body, client);
  } else {
    return of(client);
  }
}

function updateRolesObs(loggedInUser: any, rolesUsersTeams: RolesUsersTeams | undefined, client: any) {
  if (rolesUsersTeams && hasAccess(loggedInUser)("clients_assign_team_members")) {
    return putRoleAssignments(client.id, rolesUsersTeams);
  } else {
    return of(client);
  }
}

export function createClient(loggedInUser: any, body: ClientBody) {
  const { tags, rolesUsersTeams } = pruneBody(body);

  const createClientStream = fetchAsObservable(`api/clients`, {
    method: "POST",
    body: { clients: body },
  }).pipe(pluck("clients"));

  return createClientStream.pipe(
    concatMap((clientResults: any) => {
      return zip(
        updateTagsObs(tags, clientResults),
        updateSourceObs(body, clientResults),
        updateRolesObs(loggedInUser, rolesUsersTeams, clientResults)
      ).pipe(
        map(() => {
          return clientResults;
        })
      );
    })
  );
}

export function updateClient(loggedInUser: any, clientId: string, body: ClientBody) {
  const { tags, rolesUsersTeams } = pruneBody(body);

  const updateClientStream = fetchAsObservable(
    `api/clients/${clientId}?include=users,clients,tags,client_for,client_sources`,
    {
      method: "PATCH",
      body: { clients: body },
    }
  ).pipe(pluck("clients"));

  return updateClientStream.pipe(
    concatMap((clientResults: any) => {
      return zip(
        updateTagsObs(tags, clientResults),
        updateSourceObs(body, clientResults),
        updateRolesObs(loggedInUser, rolesUsersTeams, clientResults)
      ).pipe(
        map(() => {
          return clientResults;
        })
      );
    })
  );
}
