import { useCallback, useState } from "react";
import { useDebounce } from "canopy-styleguide!sofe";

export function useDebouncedSearch({
  initialValue = "",
  debounceTime = 500,
}: {
  initialValue?: string | (() => string);
  debounceTime?: number;
} = {}) {
  const [search, setSearch] = useState(initialValue);
  const [debouncedSearch, setDebouncedSearch] = useState(initialValue);
  const debounceSearch = useDebounce((val: string) => setDebouncedSearch(val), debounceTime, {}, []);

  const updateSearch = useCallback(
    (val: string) => {
      setSearch(val);
      debounceSearch(val.trim());
    },
    [debounceSearch]
  );

  return {
    search,
    debouncedSearch,
    updateSearch,
  };
}
